.appointmentRegister_page{
    width: 100%;
    height: 100vh;
    /* padding: 10px; */
    /* border: 1px solid red; */
    overflow-y: hidden;
}
.register_page_title{
    text-align: center;
    font-size: 32px;
    font-family: SimSun,STSong;
    font-weight: bolder;
    letter-spacing: 0.5em;
}
.register_page_content .ant-descriptions-item-label {
    background-color: transparent !important;
    border-right: 1px solid #000;
    border-left: 1px solid #000 !important;
    text-align: center;
    max-width: 100px !important;
    min-width: 100px !important;
    padding: 2px 5px!important;
}
.register_page_content .ant-descriptions-item-label>span{
    font-family: SimSun,STSong !important;
    font-weight: bold;

}
.register_page_content  .ant-descriptions-row td{
    padding: 2px 5px;
    text-align: center;
    border: none;
    min-width: 100px;
}
.register_page_content .ant-descriptions-bordered .ant-descriptions-view{
    border: 1px solid #000;
    border-left: none ;
    border-radius: 0px;
}
.register_page_content  .ant-descriptions-row{
    border-bottom: 1px solid #000;
}

.inner_des_box{
    padding: 0px !important;
    border: none !important;
    
}
.inner_des_box .ant-descriptions-view{
    border: none !important;
    border-radius: 0px;
}
/* 复选框 */
.des_checkgroup{
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-end;
}

.checkbox_dis_span{
    padding:0px 10px ;
    display: inline-block;
    margin: 0 5px 0 0px;
    height:20px;
    min-width: 100px;
    color: #000;
    border-bottom: 0.5px solid #000;
    font-family: SimSun, STSong;

}
.examination_checkbox{
    margin-left: 8px;
}
.examination_checkbox span {
    font-size: 14px;
    font-weight: normal;
    color: #000;
    font-family: SimSun, STSong;
}
.register_page_content .ant-checkbox-inner {
    border: 0.5px solid #000;
}

.register_page_content .ant-checkbox-checked .ant-checkbox-inner {
    background-color: transparent !important;
    border-color: #000;
}

.register_page_content .ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 1px solid #000000;
    border-top: 0;
    border-left: 0;
    -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
    -ms-transform: rotate(45deg) scale(1) translate(-50%, -50%);
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
}
.check_box_wrap{
    /* flex-direction: column; */
    flex-wrap: wrap;
    
}
.custom_line{
    height: 110px;
}
.custom_line_box{
    height: 105px;
}
.custom_line_box p{
    text-align: left;
    margin: 0px;
    padding: 5px 2px;
}
.custom_line_box p span{
    padding-right: 10px;
}