.pageHeader1 {
  background: #fff;
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding: 0 20px;
  border-bottom: 1px solid #eee;
}
.StatisticsPage-head1 {
  background-color: #43b3c2;
}
.pageHeader1 h3 {
  font-size: 18px;
  margin-bottom: 0;
  text-align: center;
  color: #fff;
}
.pageHeader1 h4 {
  font-size: 15px;
  margin-bottom: 0;
  text-align: center;
  color: #fff;
}
.StatisticsPage-box1 {
  background: #fff;
  padding: 10px;
  border-top: 1px solid #43b3c2;
  margin: 10px;
}
.StatisticsPage-search {
  overflow: hidden;
  margin-bottom: 20px;
}
.StatisticsPage-btn {
  float: left;
}
.btn {
  margin: 5px;
  margin-right: 20px;
}
.btn:last-child {
  margin-right: 0px;
}
.StatisticsPage-ipt {
  float: right;
}
.ipt1 {
  width: 100% !important;
}
.date {
  width: 250px;
  vertical-align: top;
  margin: 5px !important;
}
.form-group {
  overflow: hidden;
  margin: 15px 0;
}
.form-group-compact {
  overflow: hidden;
  margin: 5px 0;
}
.form-label1 {
  width: 30%;
  float: left;
  text-align: right;
  margin-top: 5px;
  font-weight: 600;
  font-size: 15px;
}
.form-label1-compact {
  width: 5%;
  float: left;
  text-align: right;
  margin-top: 5px;
}
.form-ipt {
  width: 66.666%;
  float: left;
}

.registrationPageMain-content {
  background: #f0f0f0;
  width: 100%;
}
.registrationPageMain-content font {
  display: inline-block;
  width: 90px;
  text-align: right;
}
.registrationPageMain-content span {
  display: inline-block;
}
.repageUserInfo {
  overflow: hidden;
}
.repageUserInfo-left .repageUserInfo-inp {
  width: 200px;
  height: 40px;
}
.repageUserInfo-left {
  width: 70%;
  float: left;
}
.repageUserInfo-leftcontent {
  width: 50%;
  float: left;
}
.repageUserInfo-rightcontent {
  width: 50%;
  float: left;
}
.repageUserInfo-right {
  width: 30%;
  float: left;
  height: 100px;
}
.repageUserInfo-right .img {
  width: 100px;
  height: 100px;
}
.registrationPageMain-content .repageUserAddr {
  clear: both;
  background: #fff;
}
.registrationPageMain-content .repageUserAddr-inp {
  width: 1200px;
  height: 40px;
}
.registrationPageMain-content .repageUserAddr-inptel {
  width: 500px;
  height: 40px;
}
.registrationPageMain-btn {
  text-align: center;
}
.registrationPageMain-btn button {
  margin-right: 60px;
  margin-top: 30px;
  width: 100px;
  height: 40px;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 8px !important;
  overflow-wrap: break-word;
}
.repageUserInfo-right img {
  margin: 0 20px;
}
.registrationPage-marginRight {
  margin-right: 10px;
  display: inline-block;
}
.ant-upload.ant-upload-select-picture-card {
  background-color: transparent !important;
  border: none !important;
}
.repageUserInfo-right {
  height: 100%;
}
.img-photo {
  display: inline-block;
}
.ant-upload.ant-upload-select-picture-card {
  width: 30px !important;
  height: 15px !important;
}
.ant-table-tbody > tr > td {
  padding: 8px !important;
}
.ant-table-selection-column {
  max-width: 10px !important;
}
label.require:before {
  content: '* ';
  color: red;
}
.form-ipt {
  margin-top: 5px;
}
.two_cancer_appoint .form-group {
  margin: 10px 0px !important;
}
.address_select {
  width: 100%;
}
.form-ipt-div {
  margin: 5px 0;
}
.yuyue_drawer .ant-drawer-body {
  padding: 15px;
}
.yuyue_drawer .form-ipt {
  /* width: 80%; */
}
.yuyue_drawer .form-label1 {
  /* width: 25% !important; */
}
.record-detail-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid #009688;
  margin-bottom: 2px;
}
.record-detail-left {
  flex: 1;
  padding: 5px;
}
.record-detail-left p {
  margin: 0px;
}
.record-detail-right {
  padding: 5px 10px;
  height: 100%;
}
.ant-picker-calendar-mode-switch {
  display: none;
}
.ant-picker-calendar-year-select {
  width: 30% !important;
}
.ant-picker-calendar-month-select {
  width: 30% !important;
}
.ant-picker-calendar-header {
  text-align: end;
}
.ant-picker-calendar-date {
  width: 60px !important;
  height: 70px !important;
}
.form-heng {
  display: flex;
  align-items: center;
  height: 70px;
  border-bottom: 1px solid #efefef;
}
.heng_label {
  width: 40%;
  font-weight: bold;
  text-align: center;
}
.heng_con {
  font-weight: bold;
  width: 57%;
}
