.sel{margin-right: 10px;}
.tab-title{padding:10px; border-bottom: 1px solid #ddd;}
.tab-org{text-align: center; margin: 30px 0;}
.result-spbox{padding: 10px;border: 1px solid #f2f2f2;}
.pageHeader{background: #fff;width: 100%;height: 50px;line-height: 50px; padding:0 20px;border-bottom: 1px solid #eee;}
.pageHeader h3{font-size: 18px; margin-bottom: 0}
.StatisticsPage-box{background: #fff; padding: 20px;}
.StatisticsPage-search{overflow: hidden; margin-bottom: 20px;}
.StatisticsPage-btn{float: left;}
.btn{margin:5px;}
.btn:last-child{margin-right: 0px;}
.StatisticsPage-ipt{float: right;}
.ipt{width: 180px !important; margin: 5px  !important;}
.ipt-search{width: 250px !important; margin: 5px  !important;}
.date{width: 250px; vertical-align: top;margin:5px !important;}
.form-group{overflow: hidden; margin: 15px 0;}
.form-group-compact{overflow: hidden; margin: 5px 0;}
.form-label{width:25%; float: left; text-align: right; margin-top: 5px;}
.form-label-compact{width:5%; float: left; text-align: right; margin-top: 5px;}
.form-ipt{width: 66.666%; float: left;}
.spbox-name{
    margin-right: 50px;
}

.registrationPageMain-content{overflow: hidden;background: #fff;width: 100%;}
.registrationPageMain-content font{display: inline-block;width: 90px;text-align: right;}
.registrationPageMain-content span {display: inline-block;}
.repageUserInfo-left .repageUserInfo-inp{width: 200px;height: 40px;}
.repageUserInfo-left{width: 70%; float: left;overflow: hidden;}
.repageUserInfo-leftcontent{width: 50%; float: left;}
.repageUserInfo-rightcontent{width: 50%; float: left;}
.repageUserInfo-right{width: 30%;float: left;height: 100px;}
.repageUserInfo-right .img{width: 100px; height: 100px;}
.registrationPageMain-content .repageUserAddr{clear: both; background: #fff } 
.registrationPageMain-content .repageUserAddr-inp{width: 1200px;height: 40px;}
.registrationPageMain-content .repageUserAddr-inptel{width: 500px;height: 40px;}
.registrationPageMain-btn{text-align: center;}
.registrationPageMain-btn button{margin-right: 60px;margin-top: 30px;width: 100px;height:40px;}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 8px !important;
    overflow-wrap: break-word;
}
.repageUserInfo-right img{
    margin: 0 20px;
}
.registrationPage-marginRight{
    margin-right: 10px;
    display: inline-block;
}