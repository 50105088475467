.login {
  height: 100vh;
  width: 100vw;
  background-image: url('../../assets/login-img/bk.png');
  -moz-background-size: 100% 100%;
  background-size: 100% 100%;
}
.login_title {
  font-family: Noto Sans S Chinese;
  font-weight: bold;
  font-size: 40px;
  color: #101113;

  padding-bottom: 80px;
}
.loginContainer {
  padding-top: 10%;
  display: flex;
  justify-content: center;
  align-items: end;
}
.loginFormtab {
  width: 500px;
  height: 580px;
  background: #ffffff;
  padding: 80px 70px 0 70px;
  margin-left: 150px;
}
.loginFormtab_p {
  height: 20px;
  font-family: Noto Sans S Chinese;
  font-weight: bold;
  font-size: 20px;
  color: #101113;
  line-height: 16px;

  display: flex;
  justify-content: center;
}
.loginBtn {
  width: 360px;
  height: 52px;
  background: #3f9dff;
}
.loginFormtab_inp {
  height: 52px;
  width: 360px;
  margin-bottom: 50px;
}
.loginFormtab_heng {
  width: 32px;
  height: 4px;
  background: #2c93ff;
  margin-top: 2px;
  margin-bottom: 50px;
}
