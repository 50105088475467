.headerContent {
  background: #ffffff;
  width: 100%;

  padding-left: 60px;

  overflow: hidden;
  z-index: 999;
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headerlogoimg {
  width: 900px;
  height: 64px;
  display: flex;
  align-items: center;
}

.headerlogoimg span {
  font-family: Noto Sans S Chinese;
  font-weight: bold;
  font-size: 24px;
  color: #283043;
}
.headerName {
  background-image: url('../../../assets/login-img/index_top.png');
  background-size: cover;
  width: 800px;
  height: 64px;
  line-height: 64px;
  text-align: right;
  padding-right: 100px;
}
.headerUser {
  color: #a7b6d7;
}

.headerGoBlack {
  background: #a7b6d7;
  color: #fff !important;
  margin-right: 20px;
}
