.ContainerMain {
  background: #e9edf2;
  position: relative;
  padding-top: 80px;
}

.containerMenu {
  background: #e9edf2;
  height: calc(100vh - 80px);
  float: left;
  width: 14%;
  overflow-y: auto;
}

.containerShow {
  float: right;
  width: 86%;
  height: calc(100vh - 80px);
  overflow-y: auto;
  background: #fff;
}

.ContainerMain {
  /* height: calc(100vh - 80px); */
  height: 100vh;
}

.page_outer_box {
  overflow: hidden;
}
