
.query_pdf_btn,.queryPdf_outer_query_box .query_pdf_btn:hover,.queryPdf_outer_query_box .query_pdf_btn:focus {
    height: 40px;
    width: 100%;
    margin: 0 auto !important;
    font-size: 18px;
    letter-spacing: 0.2em;
    text-align: center;
    background-color: rgba(17, 118, 238,0.7);
    color: white;
}
.flex-row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.query_seconds_Input{
    flex: 1;
    margin-right: 10px;
}
/* .query_seconds_btn{ */
    /* border: 1px solid rgba(17, 118, 238,0.7)!important;
    color: rgba(17, 118, 238,0.7)!important; */
/* } */
.queryPdf_outer{
   /* background-color: rgba(123, 195, 250, 0.3); */
    width: 100vw;
    min-height: 100vh;
}
.queryPdf_inner{
    background:url('../../../assets/pdfQueryBG.png') no-repeat;
    background-size: cover;
    width:100%;
    height:100vh;
}
.queryPdf_outer_header{
    padding: 5px;
    text-align: center;
    background-color: rgb(17, 118, 238);
}
.queryPdf_outer_header p{
    font-size: 20px;
    color: white;
    margin: 0px !important;
}
.queryPdf_outer_query_box{
    padding-bottom: 10px;
    border-bottom: 2px dashed rgba(17, 118, 238,0.7);
}
.queryPdf_outer_result_box{
    padding: 10px;
}
