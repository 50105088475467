.pageHeader{background: #fff;width: 100%;height: 50px;line-height: 50px; padding:0 20px;border-bottom: 1px solid #eee;}
.pageHeader h3{font-size: 18px; margin-bottom: 0}
.StatisticsPage-box{background: #fff; padding: 20px;}
.StatisticsPage-search{overflow: hidden; margin-bottom: 20px;}
.StatisticsPage-btn{float: left; }
.StatisticsPage-btn .btn{
    
    margin:0px 10px;
}

.btn{margin:5px;margin-right: 20px;}
.btn:last-child{margin-right: 0px;}
.StatisticsPage-ipt{float: right;}
.ipt{width:150px !important; margin: 5px  !important;}
.StatisticsPage-box .date{width: 250px; vertical-align: top;margin:5px !important;}
.form-group{overflow: hidden; margin: 15px 0;}
.form-group-compact{overflow: hidden; margin: 5px 0;}
.form-label{width:25%; float: left; text-align: right; margin-top: 5px;}
.form-label-compact{width:5%; float: left; text-align: right; margin-top: 5px;}
.form-ipt{width: 66.666%; float: left;}


.registrationPageMain-content{background: #f0f0f0;width: 100%;}
.registrationPageMain-content font{display: inline-block;width: 90px;text-align: right;}
.registrationPageMain-content span {display: inline-block;}
.repageUserInfo{
    overflow: hidden;
}
.repageUserInfo-left .repageUserInfo-inp{width: 200px;height: 40px;}
.repageUserInfo-left{width: 70%; float: left;}
.repageUserInfo-leftcontent{width: 50%; float: left;}
.repageUserInfo-rightcontent{width: 50%; float: left;}
.repageUserInfo-right{width: 30%;float: left;height: 100px;}
.repageUserInfo-right .img{width: 100px; height: 100px;}
.registrationPageMain-content .repageUserAddr{clear: both; background: #fff } 
.registrationPageMain-content .repageUserAddr-inp{width: 1200px;height: 40px;}
.registrationPageMain-content .repageUserAddr-inptel{width: 500px;height: 40px;}
.registrationPageMain-btn{text-align: center;}
.registrationPageMain-btn button{margin-right: 60px;margin-top: 30px;width: 100px;height:40px;}
.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 8px !important;
    overflow-wrap: break-word;
}
.repageUserInfo-right img{
    margin: 0 20px;
}
.registrationPage-marginRight{
    margin-right: 10px;
    display: inline-block;
}
.ant-upload.ant-upload-select-picture-card{
    background-color: transparent !important;
    border: none !important;

}
.repageUserInfo-right{
    height: 100%
}
.img-photo{
    display: inline-block
}
.ant-upload.ant-upload-select-picture-card{
    width: 30px !important;
    height: 15px !important;
}
.ant-table-tbody > tr > td{
   padding: 8px !important;
}
.ant-table-selection-column{
    max-width: 10px !important;
}

label.required:before {
    content: '* ';
    color: red;
}
.ipt_search{
    width: 200px !important;
    display: inline-block  !important;
    margin: 0 10px !important;
}
.cropper-point.point-se {
    bottom: -3px;
    cursor: nwse-resize;
    height: 5px !important;
    opacity: 1;
    right: -3px;
    width: 5px !important;
}
/* .uploadCrop{
    width:200px !important;
    height: 200px !important;
    overflow: hidden;
    border: 1px solid  #4EBDB9;
    display: inline-block;
} */
/* .company-logo-cropper .cropper-container{
    width:300px !important;
    height: 200px !important;
} */
/* .cropper-canvas{ */
    /* width: 50% !important;
    height: 100% !important; */
/* } */
.cropper-view-box>img{
    max-width: 400px !important;
    max-height: 300px !important;
}
.tips{
    color: rgb(128, 126, 126);
    text-align: left;
}
.file_size_tips{
    color:#009688 ;
    text-align: left;

}
.ant-notification{
    max-height: 90vh;
    overflow-y: auto;
}