y.appointquery-bg{
    margin: 0;
background: #f3f6f5;
}
.apointquery-logo{
    margin: 4em auto;
    text-align: center;
}
.appointquery-title{
    padding: 0 1em;
    color: #9b9b9b;
}
.appointquery-ipt{
    margin: 0 1em;
    padding-top: 2em;
}
.appointquery-ipt-input{
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #555;
    line-height: 2em;
    text-indent: .5em;
}
.appointquery-btn{
    background: #419ced;
    color: #fff;
    text-align: center;
    margin: 2em 1em;
    line-height: 2.2em;
    border-radius: 5px;
    cursor: pointer;
}
.form-group-single{
    border-bottom: 1px solid #dddddd;
}
.cancelAppo{
    margin-left: 18px;
}
.appointnores{
    text-align: center;
}
.upload_appoint_file .ant-upload-list{
    max-height:none !important;
}
.upload_appoint_file .ant-upload-list-item-name{
    color: #38A5B5 !important;
}
.upload_appoint_file .ant-upload-list-item{padding: 5px 8px !important;height:auto !important;}
.tip_color{color: #38A5B5;}
.appoint_info .cancelAppo {
    width: 100%;
    margin-left: 0px;
}
.require_input{
    border: 1px solid rgb(236, 134, 134);
}
.require_input  .ant-select-selector{
    border: none !important;
}
.appoint_tips p{
   color: #43B3C2;
   text-indent: 2em;
}
.appoint_tips span{
    color: red;
    text-indent: 3em;
    font-size: large;
}



