.StatisticsPage-content-read{
display: flex;
flex-direction: row;
}

.pic_box{
    width:800px;
    position: relative;
    height:800px;
    background-color:#001529 ;
    overflow: hidden;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    box-sizing:border-box;
    
}
.pic_box img{
    position: absolute;
}
#targetImage{
    position: absolute;
}
.right_box{
    flex: 1;
    height:800px;
    padding: 10px;

}
.pic{
    position: absolute;
    border: 1px solid #fff;


}
.cropper-preview{
    overflow: hidden;
    border: 1px solid  #4EBDB9;
    display: inline-block;
}
/* .cropper-modal{ */
    /* background-color:#929292 !important ; */
/* } */
.margin5{
    margin: 0 5px;
}
.show_jcsj{
    margin-bottom: 10px !important;
}
.option_btn{
    margin:  10px;
}
.picBtn .ant-btn{
    margin: 0px 10px;
}

.canvas{
    position: absolute;
    left: 1100px;
    z-index:9999;
    width: 400px;
    height: 400px;
    /* display: none; */
}

.StatisticsPage-box_read{
    overflow: hidden;
    left: 20px;
    top: 50px;
}
.canvas2{
    position:absolute;
    left: 0px;
    top: 0px;
    z-index: 9998;
}
.StatisticsPage-box_read .StatisticsPage-btn{
    width: 100%;
}
.StatisticsPage-content-read{
    width: 100%;
    padding: 5px;
    position: relative;
}
.StatisticsPage-btn span{padding: 0 5px;}
.pic_read_page{
    min-width: 1400px;
    overflow-x: auto;
}