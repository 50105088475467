.table-h3{
    padding: 10px;
    text-align: center;
    font-size: 20px;
}
.table-box{
    padding: 0 .5em;
}
.table-date{
    overflow: hidden;
}
.table-label{
   width: 75px;
   float: left;
}
.table-span{
    width: 85%;
    float: left;
    text-align: left;
}
.table-span b{
    font-weight: normal;
    margin: 0 10px;
}
.bod{
    border-bottom: 1px solid #000;
    display: block;
    width: 100px;
    height: 20px;
}

.tabel-tab{
    border:1px solid gray;
    width: 100%;
}
.tabel-font{
    border-bottom: 1px solid black;
    width: 100px;
    text-align: center;
    /* margin: 0;
    padding: 0px; */
    display: block;
}
.font1{
    border-bottom: 1px solid black;
    width: 290px;
    text-align: center;
    /* margin: 0;
    padding: 0px; */
    display: block;
}
.font2{
    border-bottom: 1px solid black;
    width: 330px;
    text-align: center;
      /* margin: 0;
    padding: 0px; */
    display: block;
}
.table-td{
    padding-top: 5px;
}                                
.table-tb{
    padding-top: 20px;
    padding-bottom: 5px;
}
.table-op{

    border:1px solid gray;
}
.font3{
    margin-bottom: 0px;
    padding-bottom: 0px;
}
.tabel-div{
   padding: 10px;
}
.table-list{
    padding: 10px;
    overflow: hidden;
}
.table-wet{

}
.table-wet p{
    margin-bottom: 0;
}